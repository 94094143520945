document.addEventListener("turbolinks:load", function () {
    const jobSelect = document.querySelector("#invoice_job_id");
    const completionSelect = document.querySelector("#invoice_completion_id");

    if (!jobSelect || !completionSelect) return; // Stop if elements are not found

    jobSelect.addEventListener("change", function () {
        const jobId = jobSelect.value;

        if (jobId) {
            fetch(`/jobs/${jobId}/completions_without_invoice`)
                .then(response => response.json())
                .then(data => {
                    completionSelect.innerHTML = '<option value=""></option>';

                    data.forEach(completion => {
                        let option = document.createElement("option");
                        option.value = completion.id;
                        option.textContent = completion.formatted_title;
                        completionSelect.appendChild(option);
                    });
                })
                .catch(error => console.error("Error fetching completions:", error));
        }
    });
});
